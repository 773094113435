import Vue from 'vue'
import { domain, required } from '@/lib/calendesk-js-library/forms/validators'
import { mapActions, mapGetters } from 'vuex'
import slugify from 'slugify'
import { isSubdomain } from '@/lib/calendesk-js-library/tools/helpers'

export default Vue.extend({
  data () {
    return {
      userSubdomainFormatted: null as string | null,
      userDomainFormatted: null as string | null,
      rules: {
        required,
        domain
      },
      protocol: 'https://',
      calendeskDomain: '.calendesk.net',
      loading: false,
      valid: false
    }
  },
  methods: {
    ...mapActions({
      openDialog: 'dialog/open',
      closeDialog: 'dialog/close',
      fetchDomains: 'domain/fetch',
      addDomain: 'domain/add',
      updateDomain: 'domain/update',
      removeDomain: 'domain/remove',
      openConfirmDialog: 'confirmDialog/open',
      closeConfirmDialog: 'confirmDialog/close',
      setCommonDialogLoader: 'loader/setCommonDialogLoader'
    }),
    parseSubdomain (domain: string): string {
      return slugify(domain, { lower: true, remove: /[!@#$%^&*()_+{}[\];'"”„|~`=,./?]/g })
    },
    parseDomain (domain: string): string {
      domain = this.$helpers.replaceFromArray(domain, ['http://', 'https://', 'www.'], '')
      return slugify(domain, { lower: true, remove: /[!@#$%^&*()_+{}[\];'"”„|~`=,/?]/g })
    }
  },
  created () {
    if (this.defaultDomain) {
      if (this.isUsingCustomDomain) {
        this.userDomain = this.defaultDomain.domain
      } else {
        this.userSubdomain = this.defaultDomain.domain.replace(this.calendeskDomain, '')
      }
    }
  },
  computed: {
    ...mapGetters({
      defaultDomain: 'domain/getDefaultDomain'
    }),
    isUsingCustomDomain (): boolean {
      return this.defaultDomain ? !this.defaultDomain.domain.includes(this.calendeskDomain) : false
    },
    userSubdomain: {
      get (): string | null {
        return this.userSubdomainFormatted
      },
      set (value: string): void {
        this.userSubdomainFormatted = this.parseSubdomain(value)
      }
    },
    userDomain: {
      get (): string | null {
        return this.userDomainFormatted
      },
      set (value: string): void {
        this.userDomainFormatted = this.parseDomain(value)
      }
    },
    userDomainIsSubdomain (): boolean {
      return isSubdomain(this.userDomain)
    },
    fullSubdomain (): string {
      return this.protocol + this.userSubdomainFormatted + this.calendeskDomain
    },
    fullDomain (): string {
      return this.protocol + this.userDomainFormatted
    }
  }
})
