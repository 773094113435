
























































































































































































































import Dialog from '@/calendesk/models/Dialog'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import mixins from 'vue-typed-mixins'
import DomainActions from '@/components/dialogs/components/domains/mixins/DomainActions'
import { errorNotification } from '@/lib/calendesk-js-library/tools/notification'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import CalendeskInformationMessage from '@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'
export default mixins(DomainActions).extend({
  name: 'AddAndUpdateYourDomain',
  components: { CalendeskInformationMessage },
  data () {
    return {
      currentStep: 1,
      domainChecked: false,
      domainVerified: false
    }
  },
  methods: {
    backDialogHandle (): void {
      this.openDialog(new Dialog(true, dialogTypes.BUILDER_UPDATE_DOMAIN, this.$trans('domain_configuration_title'), dialogSize.LARGE))
    },
    nextStep (): void {
      if (!this.valid) return
      this.currentStep++
    },
    resetStep (): void {
      if (this.currentStep === 1) {
        this.closeDialog()
      } else {
        this.currentStep = 1
      }
    },
    checkDomainHandle () {
      if (this.defaultDomain && this.defaultDomain.domain) {
        if (this.defaultDomain.domain === this.userDomain) {
          this.shouldCheckDomain()
        } else {
          this.updateDomainHandle()
        }
      } else {
        this.addDomainHandle()
      }
    },
    shouldCheckDomain () {
      pushEvent('wbCheckOwnDomain')
      this.domainChecked = false
      this.domainVerified = false
      this.loading = true
      this.$emit('loading', this.loading)
      this.fetchDomains()
        .then(() => {
          this.domainVerified = this.defaultDomain.isValid
        })
        .finally(() => {
          this.domainChecked = true
          this.loading = false
          this.$emit('loading', this.loading)
        })
    },
    addDomainHandle () {
      pushEvent('wbCreateOwnDomain')
      this.domainChecked = false
      this.domainVerified = false
      this.loading = true
      this.$emit('loading', this.loading)
      this.addDomain(this.fullDomain)
        .then(domain => {
          this.domainVerified = domain.isValid
        })
        .catch(error => {
          errorNotification('editor_domain_error_message', error, false)
        })
        .finally(() => {
          this.loading = false
          this.$emit('loading', this.loading)
          this.domainChecked = true
        })
    },
    updateDomainHandle () {
      pushEvent('wbUpdateOwnDomain')
      this.domainChecked = false
      this.domainVerified = false
      this.loading = true
      this.$emit('loading', this.loading)
      const domain = cloneClassObject(this.defaultDomain)
      domain.domain = this.fullDomain
      this.updateDomain(domain)
        .then(() => {
          this.shouldCheckDomain()
        })
        .catch(error => {
          errorNotification('editor_domain_error_message', error, false)
        })
        .finally(() => {
          this.loading = false
          this.$emit('loading', this.loading)
        })
    },
    saveDomainHandle () {
      this.$emit('close')
    }
  }
})
